const faqs = [
    {
      id: 1,
      question: "J’ai un bug sur mon site internet/application, que faire ?",
      answer:
        "Pas d’inquiétude, si c’est un bug de sécurité, nous le corrigerons sans frais (des limites peuvent s’appliquer)," +
          " autrement cela dépends des accord passé lors de la réalisation et du type de bug, pour obtenir des détails " +
          "contactez-nous. Nous sommes susceptibles de régler automatiquement des bugs si nous en trouvons."
    },
    {
        id: 2,
        question: "J’aurais besoin d’une nouvelle fonctionnalité.",
        answer:
            "Que ce soit sur une application développée par _blank ou par une autre enterprise ; nous pouvons " +
            "probablement vous aider. Pour aller plus loin ou obtenir un devis, contactez-nous !"
    },
    {
        id: 3,
        question: "Mon site internet a été suspendu que faire ?",
        answer:
            "Nous ne suspendons les services de nos clients qu’après une absence de paiement et plusieurs relances, si " +
            "toutefois votre service est suspendu et que vous pensez que c’est une erreur, consultez votre espace client" +
            " _blank ou contactez-nous pour obtenir de l’aide."
    },
    {
        id: 4,
        question: "Quel accompagnement pour mon premier site web ? ",
        answer:
            "Nous sommes prêts à vous accompagner dans la réalisation de vos sites et application du premier pixel à la " +
            "mise en ligne. Nous fournissons toutes sortes de services et solutions afin de vous permettre de comprendre " +
            "comment fonctionne l’univers informatique."
    },
    {
        id: 5,
        question: "J’ai des besoins spécifiques pour mon projet, pouvez-vous y répondre ?",
        answer:
            "Sans doute ! _blank est une entreprise très polyvalente ainsi, nous sommes capables de fournir de nombreux " +
            "services et d’utiliser de nombreuses technologies, nous pouvons même vous conseiller dans d’autres domaines" +
            " que l’informatique selon les cas, si votre projet dispose déjà d’un cahier des charges ou d’une liste des " +
            "besoins spécifiques de votre application, contactez-nous pour en parler."},
    {
      id: 6,
      question: "Quels sont vos tarifs ?",
      answer:
        "Nos tarifs sont très variables en fonction de votre demande, si votre projet est déjà précis, vous pouvez nous " +
          "contacter pour obtenir un devis, sinon vous pouvez consulter notre plaquette tarifaire pour obtenir plus de " +
          "détails concernant notre fourchette de prix."
    }
]

export default function FAQ() {
return (
    <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 divide-y-2 divide-gray-200 sm:py-24 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-gray-900">Foire aux Questions</h2>
            <div className="mt-6 pt-10">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
                    {faqs.map((faq) => (
                    <div key={faq.id}>
                        <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                        <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                    </div>
                    ))}
                </dl>
            </div>
        </div>
    </div>
)
}
