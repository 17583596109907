import kylian from '../../img/avatar/kylian-mallet.jpg'
import vasco from '../../img/avatar/vasco-compain.jpg'

const team = [
    {
        name: 'Kylian Mallet',
        role: 'Gérant / Responsable Commercial',
        imageUrl: kylian,
        bio: 'Entrepreneur aguerri, Kylian à co-fondé _blank. Fort de son expérience sur de nombreux projets, il vous ' +
            'accompagne dans votre aventure entrepreneuriale.',
    },
    {
        name: 'Vasco Compain',
        role: 'Gérant / Responsable Technique',
        imageUrl: vasco,
        bio: 'Fort de 6 ans d’expérience dans le web, Vasco à co-fondé _blank afin de promouvoir l’accessibilité aux ' +
            'services informatiques pour tous.'
    }
]

export default function Teams(props) {
    return (
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Notre Equipe</h2>
            <ul className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
              {team.map((person) => (
                <li key={person.name}>
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                    <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                      <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="notre équipes" />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3>{person.name}</h3>
                          <p className="text-orange-500">{person.role}</p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500">{person.bio}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
}
