import Illustration from "../../img/workplace.jpeg"

export default function Example() {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-orange-400 font-semibold tracking-wide uppercase">_blank</h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Notre histoire
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={Illustration}
                    alt="bureaux"
                    width={1184}
                    height={1376}
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-gray-500">
                Après de nombreux projets avec comme équipe Kylian à la barre et Vasco au développement, ils ont choisi
                de se poser de créer une entreprise : _blank SARL.
              </p>
            </div>
            <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                Face à des études manquant de clartés et un système dépassé par les avancées technologiques.
                Ils ont fait le choix ambitieux de partir sur leur propre voie pour apprendre par eux même avec les
                moyens actuels.
              </p>
              <p>
                Dès leurs débuts, ils ont commencé à réunir une équipe de passionnés dans tous les domaines de l’informatique ;
                ils ont également commencé le développement de nouveaux services innovants afin de résoudre des problèmes récurent
                dans le domaine de l’informatique afin de fournir de meilleurs résultats, plus rapidement.
              </p>
              <p>
                Grâce à une grande expérience dans la réalisation de projets informatique,
                l’équipe de _blank ne cesse d’innover afin de produire de meilleurs produits et services
                afin de vous permettre de vivre une transition ou un renouvellement informatique le plus aisé possible.
              </p>
              <h3>Pourquoi “_blank” ?</h3>
              <p>
                Lorsque que vous cliquez sur un lien avec pour objectif “_blank”,
                cela signifie l’ouvrir dans une nouvelle page nommée “about:blank”. C’est sur cette idée que nous avons choisie notre nom,
                vous partez d’une page blanche et nous somme là pour y apporter notre savoir et notre expertise afin que cette page devienne votre plus beau site.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
