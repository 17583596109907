import {
    CodeIcon,
    PuzzleIcon,
    CreditCardIcon,
    TerminalIcon,
    DatabaseIcon,
    PencilAltIcon,
    AtSymbolIcon,
    TemplateIcon,
} from '@heroicons/react/outline'

const features = [
    {
            name: 'Application web',
            description: 'Mettez votre projet en avant avec une superbe application web. Apportez-nous votre cahier des charges, on s’en occupe !',
            icon: CodeIcon,
        },
        {
            name: 'Extensions',
            description: 'Notre équipe est polyvalente et maîtrise beaucoup de domaines. Nous saurons nous adapter à vos projets, extension chrome.',
            icon: PuzzleIcon,
        },
        {
            name: 'Hébergement',
            description: 'Vous pouvez héberger vos projets chez _blank pour ne plus vous en occuper, nous le faisons à votre place.',
            icon: DatabaseIcon,
        },
        {
            name: 'Intégration & API',
            description: 'Nous pouvons faire interagir votre site avec tout un tas de services : Discord, GitHub, Google, etc.',
            icon: TerminalIcon,
        },
        {
            name: 'Système de connexion',
            description: 'Si votre projet a besoin de gérer des comptes clients/utilisateur, nous proposons notre service de connexion déployable en 1 click.',
            icon: AtSymbolIcon,
        },
        {
            name: 'Chartes graphiques',
            description: 'Nous allons créer votre identité visuelle afin de pouvoir définir votre communication auprès de vos clients en toute sérénité.',
            icon: PencilAltIcon,
        },
        {
            name: 'Maquettes web',
            description: 'Votre maquette sera faite selon les normes du web afin de correspondre au mieux à vos besoins et aux attentes de vos clients.',
            icon: TemplateIcon,
        },
        {
            name: 'Système de paiement',
            description: 'Grâce à notre intégration avec Stripe, encaissez des paiements depuis votre site Internet sans stress.',
            icon: CreditCardIcon,
    },
]

export default function Features() {
    return (
        <div className="bg-gray-900">
            <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
                <h2 className="text-3xl font-extrabold text-white tracking-tight">Votre imagination est notre seule limite.</h2>
                <p className="mt-4 max-w-3xl text-lg text-white">
                    _blank vous proposent une multitude de services pour construire au mieux vos projets.
                    Site web, charte graphique, extension Chrome, faite votre choix !
                </p>
                <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
                    {features.map((feature) => (
                        <div key={feature.name}>
                            <div>
                                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                </span>
                            </div>
                            <div className="mt-6">
                                <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                                <p className="mt-2 text-base text-white">{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
