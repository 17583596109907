// import verticalBlank from '../../img/verticalBlank.png'

export default function JoinPanel() {
    return (
      <div className="hidden lg:block bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="bg-orange-400 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">Nous rejoindre ?</span>
                  <span className="block">Postulez pour devenir partenaire de _blank.</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-white">
                  Découvrez nos postes, offres d'emploi, missions pour freelance, et bien plus encore.
                </p>
                <a
                  href="/nous-rejoindre"
                  className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-orange-400"
                >
                  Devenir partenaire
                </a>
              </div>
            </div>
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              {/*<img*/}
              {/*  className="transform h-20 translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"*/}
              {/*  src={verticalBlank}*/}
              {/*  alt="App screenshot"*/}
              {/*/>*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
  