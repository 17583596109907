import React from 'react'
import { Helmet } from "react-helmet"
import RippleButton from "../components/btnRipple"
import { InboxIcon, SparklesIcon } from '@heroicons/react/outline'

// import verticalBlank from "../img/vertical_blank.png"
import avatar from "../img/Avatar.png"
import DevImg from "../img/services/dev.png"
import Solutions from "../img/services/solutions.png"
import Design from "../img/services/design.png"
import Hebergement from "../img/services/herbergement.png"

const faqs = [
    {
        question: 'Quels services ne proposez-vous pas ?',
        answer:
            'Nous ne proposons pas les services pour lesquels nous pensons manquer d’expertise, ainsi, nous ne proposons ' +
            'pas de développer des applications natives pour Windows, MacOS et Linux. Ne nous fournissons également pas les' +
            ' services de conseil en sécurité d’infrastructure de grande taille. Nous ne sommes également pas en mesure ' +
            'de fournir certains autres services, pour en savoir plus, contactez-nous.',
    },
    {
        question: 'Vos tarifs indiquent la mention “à partir de”, pourquoi ?',
        answer:
            'Il est difficile de donner un tarif avec précision sans analyser au préalable votre projet. Ainsi, nous vous' +
            ' fournissons une estimation du prix minimal de chaque services. Si vous souhaitez avoir une estimation ' +
            'plus détaillée, vous pouvez nous contacter pour obtenir un devis.',
    },
    {
        question: 'Mon serveur ne s’allume pas, puis-je le faire avec des allumettes ?',
        answer:
            'Nous vous déconseillons d’essayer, si vous avez-besoin d’aide avec votre infrastructure, nous pouvons ' +
            'probablement vous aider, contactez-nous pour en parler !',
    },
]

/*
    TODO :  patch grid
 */

const Services = () => {
    return (
        <>
            <Helmet>
                <title>_blank | Nos services</title>
            </Helmet>

            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-base font-semibold text-orange-400 tracking-wide uppercase">nos services</h2>
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                            Votre seule limite, votre imagination.
                        </p>
                        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                            Planifiez l’étendue de votre projet grâce à nos différent services et offres.
                        </p>
                    </div>
                </div>
            </div>

            <div className="relative bg-white pt-16 pb-32 overflow-hidden">
                <div id="development" className="relative">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0" data-aos="fade-up">
                            <div>
                                <div>
                                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                                      <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Développement web
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Que vous soyez un débutant ou que vous ayez des années d’expertise, nous pouvons
                                        vous accompagner et répondre à tout vos besoin. Nous pouvons réaliser de nombreux
                                        type de sites internet tels que des portfolios, des boutiques en lignes, des
                                        applications de gestion, des services d’intranets et bien plus encore.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-8 border-t border-gray-200 pt-6">
                                <blockquote>
                                    <div>
                                        <p className="text-base text-gray-500">
                                            “Un très bon accompagnement lors de la création de mon application web. Une équipe rapide toujours à l’écoute !”
                                        </p>
                                    </div>
                                    <footer className="mt-3">
                                        <div className="flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                                <img className="h-6 w-6 rounded-full" src={avatar} alt="profil" />
                                            </div>
                                            <div className="text-base font-medium text-gray-700">Clement Bouchet, Co-Fondateur Baril Deco</div>
                                        </div>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0">
                            <div className="grid place-items-center pl-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className="h-auto w-3/5 lg:w-9/12 lg:absolute lg:left-0 lg:max-w-none"
                                    src={DevImg}
                                    alt="Développement web"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="solutions" className="mt-24">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div data-aos="fade-up">
                                <div>
                                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                                      <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Solutions informatiques
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Que vous ayez besoin d’automatiser des tâches, d’aide dans la gestion des
                                        services informatique de votre entreprise ou bien encore d’une application pour
                                        mobile, nous pouvons répondre à vos besoins et exigences, contactez-nous pour en
                                        discuter !
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                            <div className="grid place-items-center pr-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className="h-auto w-3/5 lg:w-9/12 lg:absolute lg:right-0 lg:max-w-none"
                                    src={Solutions}
                                    alt="Solutions informatiques"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="relative bg-white pt-16 pb-32 overflow-hidden">
                <div id="hosting" className="relative">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                            <div data-aos="fade-up">
                                <div>
                                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                                      <InboxIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Hébergement
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Souvent, trouver et mettre en place un hébergement peut être une vraie prise de
                                        tête, sans parler du support technique... Nous vous accompagnons et vous
                                        fournissons des solutions d’hébergement adaptées à vos besoins. Nous proposons
                                        également tout l’écosystème qui vient avec tel que le support et la mise en
                                        place de votre application, et ce, à moindre coût.
                                    </p>
                                </div>
                            </div>
                            <div className="hidden mt-8 border-t border-gray-200 pt-6">
                                <blockquote>
                                    <div>
                                        <p className="text-base text-gray-500">
                                            &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur
                                            donec aliquet. Mi venenatis in euismod ut.&rdquo;
                                        </p>
                                    </div>
                                    <footer className="mt-3">
                                        <div className="flex items-center space-x-3">
                                            <div className="flex-shrink-0">
                                                <img
                                                    className="h-6 w-6 rounded-full"
                                                    src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="text-base font-medium text-gray-700">Marcia Hill, Digital Marketing Manager</div>
                                        </div>
                                    </footer>
                                </blockquote>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0">
                            <div className="grid place-items-center pl-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className="h-auto w-3/5 lg:w-9/12 lg:absolute lg:left-0 lg:max-w-none"
                                    src={Hebergement}
                                    alt="Hébergement Web"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div id="design" className="mt-24">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                            <div data-aos="fade-up">
                                <div>
                                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                                      <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                </div>
                                <div className="mt-6">
                                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                        Design
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Pour compléter votre gamme de produits, rien de mieux qu’un système professionnel
                                        afin de garantir une cohérence visuelle pour vos clients et vos employés.
                                        Grâce à nos solutions peu coûteuses, boostez votre productivité grâce à une
                                        interface et une charte graphique claire et sans concession.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                            <div className="grid place-items-center pl-4 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                <img
                                    className="h-auto w-3/5 lg:w-9/12 lg:absolute lg:left-0 lg:max-w-none"
                                    src={Design}
                                    alt="Design"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-gray-800">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                        <p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
                            Nos tarifs
                        </p>
                        <p className="max-w-xl mt-5 mx-auto text-xl text-white mb-10">
                            Nous avons mis à disposition une plaquette tarifaire afin d’avoir une première estimation de votre projet.
                        </p>
                        <RippleButton value="Voir nos tarifs" href="https://underscore-blank.io/plaquette-tarifaire.pdf" target="_blank" />
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                    <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                        <div>
                            <h2 className="text-3xl font-extrabold text-gray-900">Foire Aux Questions</h2>
                            <p className="mt-4 text-lg text-gray-500">
                                Vous ne trouvez pas ce que vous cherchez ? Vous pouvez toujours <a href="/contact" className="font-medium text-orange-500">nous contacter</a>.
                            </p>
                        </div>
                        <div className="mt-12 lg:mt-0 lg:col-span-2">
                            <dl className="space-y-12">
                                {faqs.map((faq) => (
                                    <div key={faq.question}>
                                        <dt className="text-lg leading-6 font-medium text-gray-900">{faq.question}</dt>
                                        <dd className="mt-2 text-base text-gray-500">{faq.answer}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden lg:block bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                        <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                            <div className="lg:self-center">
                                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                                    <span className="block">Prêt à démarrer ?</span>
                                    <span className="block">Commencez dés aujourd’hui.</span>
                                </h2>
                                <p className="mt-4 text-lg leading-6 text-white">
                                    Commencez à travailler avec nous dés aujourd’hui. Recevez une estimation de votre projet.
                                </p>
                                <a
                                    href="/contact"
                                    className="mt-8 bg-orange-500 border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white"
                                >
                                    Nous Contacter
                                </a>
                            </div>
                        </div>
                        <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                            {/*<img*/}
                            {/*    className="transform h-20 translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"*/}
                            {/*    src={verticalBlank}*/}
                            {/*    alt="App screenshot"*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services
