import React from 'react'
import Helmet from 'react-helmet'

import Header from '../components/about/Header.jsx'
import Teams from '../components/about/Teams.jsx'
import Partners from "../components/about/Partners.jsx";
import TeamsContent from '../components/about/TeamsContent.jsx'
import JoinPanel from '../components/about/JoinPanel.jsx'

const About = () => {
    return (
        <>
            <Helmet>
                <title>_blank | Qui sommes-nous ?</title>
            </Helmet>
            <Header />
            <Teams />
            <TeamsContent />
            <Partners />
            <JoinPanel />
        </>
    )
}

export default About