import React from 'react'
import Helmet from 'react-helmet'

const NotFound = () => {
    let messages = [
        'Il semblerait que vous soyez perdus',
        'Oups, il y a une erreur',
        'Page introuvable',
        'Méditez un instant devant cette page vide pleine de promesses.'
    ]

    document.addEventListener('DOMContentLoaded', () => {
        setInterval(updateMessage, 10e3)
        updateMessage()
    })

    let lastI = null

    const updateMessage = () => {
        let i

        do {
            i = getRandomInt(0, messages.length - 1)
        } while (i === lastI)

        let el = document.querySelector('.message')
        el.style.transition = 'opacity .5s ease'
        el.style.opacity = '100%'

        requestAnimationFrame( () => {
            el.style.opacity = '0%'
            setTimeout( () => {
                el.innerHTML = messages[i]
                lastI = requestAnimationFrame( () => {
                    el.style.opacity = '100%'
                })
            }, 500)
        })
    }

    const getRandomInt = (min, max) => {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min - 1) + min)
    }

    return (
        <>
            <Helmet>
                <title>Erreur 404 | page non trouvée</title>
            </Helmet>

            <div className="grid place-items-center text-center h-semi">
                <div className="wrapper">
                    <h1 className="uppercase text-orange-400 font-semibold">Oups, vous vous êtes perdus !</h1>
                    <p className="text-9xl font-extrabold text-indigo-800 my-5">404</p>
                    <p className="font-normal text-lg text-gray-500 message"/>
                </div>
            </div>
        </>
    )
}

export default NotFound