import React from 'react'
import Helmet from 'react-helmet'

const Cookies = () => {
    return (
        <>
        <Helmet>
            <title>_blank | Politique de confidentialité et des cookies</title>
        </Helmet>

        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
              <svg
                className="absolute top-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
              </svg>
              <svg
                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
              </svg>
              <svg
                className="absolute bottom-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="block text-base text-center text-orange-400 font-semibold tracking-wide uppercase">
                    cookies & confidentialité
                </span>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-indigo-600 sm:text-4xl">
                    Politique de confidentialité et des cookies
                </span>
              </h1>
            </div>
            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto text-justify">
              <p>
                En visitant notre site Web et en utilisant nos services, vous comprenez et acceptez la façon dont nous traitons les données personnelles conformément à notre politique de confidentialité. 
                Nous respectons votre vie privée et le droit de contrôler vos données personnelles. Nos principes directeurs sont simples. 
                Nous allons être ouverts avec quelles données nous collectons et pourquoi. Veuillez prendre un moment pour les lire. C'est important !
              </p>
              <p>
                _blank respecte votre vie privée et le droit de contrôler vos données personnelles. 
                Nos principes directeurs sont simples. 
                Nous serons ouverts à vous informer sur les données que nous collectons et pourquoi. 
                Nous protégeons vos données comme si c'était notre plus grand secret commercial.
              </p>
              <p>
                La politique de confidentialité de _blank ("Société") décrit quelles données nous collectons, le but du traitement, comment vous pouvez contrôler vos propres données et comment nous contacter. 
                La présente politique de confidentialité s'applique lorsque vous utilisez des applications, des pages Web et des services qui sont inclus dans une application ou sur un site Web géré par la Société. 
                Le DPO de _blank est une personne personnellement responsable conformément à ces conditions et est responsable du traitement conformément à la législation en vigueur.
              </p>
              <p>
                Voici un résumé de ce à quoi vous pouvez vous attendre en visitant notre site Web:
              </p>
              <ul>
                  <li>
                    Nous suivons votre activité web à des fins de marketing.
                  </li>
                  <li>
                    Nous recueillons des renseignements personnels à des fins de marketing et de communication.
                  </li>
                  <li>
                    Nous traitons les renseignements personnels en les envoyant à des systèmes intégrés de courriel et de gestion de la relation client (CRM) à des fins de marketing et de relations clients.
                  </li>
                  <li>
                    Nous sauvegardons les informations personnelles dans nos bases à des fins d'analyse.
                  </li>
                  <li>
                    Votre comportement et votre activité sur le Web peuvent être utilisés à des fins de marketing comme le ciblage et l'analyse marketing.
                  </li>
              </ul>
              <h2 className="text-left">QUELLES DONNÉES PERSONNELLES COLLECTONS-NOUS ?</h2>
              <p>
                _blank recueille les informations principalement auprès de vous en tant que client. 
                Des exemples de données personnelles que nous pourrions collecter sont le nom de votre entreprise, votre nom et d'autres détails de contact tels que le numéro de téléphone, l'adresse e-mail ou l'adresse physique.
                _blank peut mettre à jour vos informations à partir d'autres dossiers publics si cela est nécessaire pour maintenir un bon entretien du référentiel de donnée.
              </p>
              <p>
                _blank enregistre les données personnelles en relation avec :
              </p>
              <ul>
                  <li>
                    Notification d'un service offert par le biais des différentes plateformes offertes par la Société.
                  </li>
                  <li>
                    Lorsque vous entrez votre adresse e-mail ou votre numéro de téléphone portable sur le site.
                  </li>
                  <li>
                    Si vous nous contactez par courriel, par téléphone, par nos sites Web et par les médias sociaux.
                  </li>
                  <li>
                    Si vous vous inscrivez à un abonnement par courriel.
                  </li>
                  <li>
                    Votre visite de sur nos sites Web et lors de l'accès à notre application, y compris, mais sans s' y limiter, les données de trafic, les données de localisation, les blogs et d'autres données de communication, telles que l'adresse IP, le type de périphérique, le système d'exploitation et le type de navigateur utilisé pour la visite.
                  </li>
              </ul>
              <h2>COLLECTE AVEC CONSENTEMENT</h2>
              <p>
                En cliquant sur le bouton "Fermer", vous consentez à ce que les données personnelles (société, nom, numéro de téléphone et adresse électronique) que vous entrez dans des formulaires à différents endroits sur ce site puissent être utilisées à des fins de marketing pour les services auxquels vous êtes exposé lorsque vous êtes invité à remplir vos informations personnelles. 
                Nous recueillons également votre adresse IP, votre position géographique et vos intérêts afin de mieux adapter notre marketing à vos besoins.
              </p>
              <p>
                En cliquant sur le bouton "Fermer", vous acceptez également que nous puissions ajouter un cookie tiers sur votre ordinateur à partir de la Société. 
                Ce cookie vous suivra, ainsi que votre comportement en ligne sur d'autres sites Web, afin de vous proposer du marketing basé sur votre comportement en ligne.
              </p>
              <h2 className="text-left">COMMENT NOUS UTILISONS LES INFORMATIONS COLLECTÉES</h2>
            </div>
          </div>
        </div>
        </>
      )
}

export default Cookies