import React from 'react'
import Helmet from 'react-helmet'

import JoinUs from '../components/contact/JoinUs.jsx'
import Support from '../components/contact/Supports.jsx'
import FAQ from '../components/contact/FAQ.jsx'
import ContactModal from "../components/contact/contactModal.jsx";

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>_blank | Contact</title>
            </Helmet>
            <Support />
            <FAQ />
            <JoinUs />

            <ContactModal />
        </>
    )
}

export default Contact