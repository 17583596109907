import { PencilIcon, HeartIcon } from '@heroicons/react/outline'
import desktopMac from '../../img/desktopMac.png'
import figmaWindow from '../../img/figmaWindow.png'
import RippleButton from '../btnRipple.jsx'

export default function MainFeatures() {
  document.addEventListener('mousemove', parallax)

  function parallax (e) {
      this.querySelectorAll('.layer').forEach(layer => {
          const speed = layer.getAttribute('data-speed')

          const x = ((window.innerWidth - 2000) - e.pageX*speed) / 100
          const y = ((window.innerHeight - 2000) - e.pageY*speed) / 100

          layer.style.transform = `translateX(${x}px) translateY(${y}px)`
      })
  }

  return (
    <div id="features" className="relative bg-white pt-16 pb-32 overflow-hidden">
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div data-aos="fade-up">
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                  <PencilIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Le design est une de nos priorités
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                    Rendez votre projet attractif et développez une image forte et soignée.
                    Ici, le souci du détail est une qualité et ne laissons place à aucun faux pas.
                    Prenez un coup d’avance sur la concurrence avec un site moderne et fiable.
                </p>
                <div className="mt-6">
                  <RippleButton href="/services" value="Nos Services" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:h-full">
              <img className="relative lg:w-auto lg:max-w-none" src={desktopMac} alt="Inbox user interface" />
              <img data-speed="4" className="hidden absolute lg:block top-0 w-full lg:w-auto layer" src={figmaWindow} alt="la maquette figma de _blank" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div data-aos="fade-up">
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                  <HeartIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Détendez-vous, on s’occupe de tout
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                    Ne perdez pas vos potentiels clients avec du charabia, concentrez-vous sur l’essentiel !
                    Trouver le contenu approprié paraît simple, et pourtant cela peut facilement devenir un casse-tête.
                    Ici, nous plaçons les informations là où on les attend.
                </p>
                <div className="mt-6">
                  <a href="/contact" className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600">
                    Commencer
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <svg data-aos="fade-up-right" width="547" height="337" viewBox="0 0 547 337" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 312.9L546.7 315.9C546.7 315.9 283.7 365 0 312.9Z" fill="#FDBA74"/>
                <path d="M275.3 56.6992H268.9V285.399H275.3V56.6992Z" fill="#666666"/>
                <path d="M314.2 77.3H232.6L258.7 0H288.3L314.2 77.3Z" fill="#E8AD3A"/>
                <path d="M263.5 9.30078L249.9 70.7008C249.9 70.6008 247.4 25.3008 263.5 9.30078Z" fill="#D39933"/>
                <path d="M282.8 9.69922L300 70.1992C299.9 70.1992 299.7 24.6992 282.8 9.69922Z" fill="#D39933"/>
                <path d="M272.1 3.59961L273.5 66.3996C273.5 66.3996 284.7 22.3996 272.1 3.59961Z" fill="#D39933"/>
                <path d="M474.3 284.7L470.5 317.4H461.3L459.6 284.5L474.3 284.7Z" fill="#7F4C28"/>
                <path d="M75.7998 284.7L79.6998 317.4H88.8998L90.5998 284.5L75.7998 284.7Z" fill="#7F4C28"/>
                <path d="M472.5 185.599L425.7 184.299L427.5 239.699L472.5 253.799V185.599Z" fill="#325B6B"/>
                <path d="M74.2002 185.099L121 183.799L119.2 239.099L74.2002 253.299V185.099Z" fill="#325B6B"/>
                <path d="M438.5 240.1L423.3 173C417.6 147.9 395.3 130.1 369.6 130.1H177C151 130.1 128.5 148.3 123.1 173.8L109.1 240.2H438.5V240.1Z" fill="#48768C"/>
                <path d="M433.3 237.699H285.5H262H114.2C99.0999 237.699 86.8999 248.799 86.8999 262.399V295.399H262H285.5H460.6V262.399C460.6 248.699 448.4 237.699 433.3 237.699Z" fill="#608EA0"/>
                <path d="M99.9 295.9V201.9C99.9 192.6 92.4 185.1 83.1 185.1H76.3C67 185.1 59.5 192.6 59.5 201.9V295.9H99.9Z" fill="#48768C"/>
                <path d="M446.8 295.9V202.4C446.8 193.1 454.3 185.6 463.6 185.6H470.4C479.7 185.6 487.2 193.1 487.2 202.4V295.9H446.8Z" fill="#48768C"/>
                <path d="M79.7002 215.7C83.29 215.7 86.2002 212.655 86.2002 208.9C86.2002 205.144 83.29 202.1 79.7002 202.1C76.1103 202.1 73.2002 205.144 73.2002 208.9C73.2002 212.655 76.1103 215.7 79.7002 215.7Z" fill="#325B6B"/>
                <path d="M80.8 223.5V278.1C80.8 278.1 69.7 250.8 80.8 223.5Z" fill="#325B6B"/>
                <path d="M446.9 261.1H99.8999V295.9H446.9V261.1Z" fill="#48768C"/>
                <path d="M206.4 140.5V209.6C206.4 209.6 192.4 175.1 206.4 140.5Z" fill="#325B6B"/>
                <path d="M468 213.2C471.59 213.2 474.5 210.155 474.5 206.4C474.5 202.644 471.59 199.6 468 199.6C464.41 199.6 461.5 202.644 461.5 206.4C461.5 210.155 464.41 213.2 468 213.2Z" fill="#325B6B"/>
                <path d="M336.6 142.699V211.799C336.6 211.799 350.6 177.299 336.6 142.699Z" fill="#325B6B"/>
                <path d="M466.9 220.9V275.5C466.9 275.5 478 248.2 466.9 220.9Z" fill="#325B6B"/>
                <path d="M105.7 275.5H443.7C443.7 275.5 328.1 290.3 105.7 275.5Z" fill="#325B6B"/>
                <path d="M249.8 185.6C249.8 185.6 236.4 220.5 237 226.3C237.6 232.1 241.7 239.2 244.4 239.2L290.2 239.5C290.2 239.5 295.5 238.5 298.2 229.7C300.9 221 309.6 187.8 309.6 186.8C309.6 185.8 310.8 180.2 305.5 176C300.3 171.7 265.7 172.8 259.1 174.8C254.3 176.4 252.4 178.7 249.8 185.6Z" fill="#DE8B2E"/>
                <path d="M300.3 178.799L283.5 235.199C283.5 235.199 307.2 204.099 300.3 178.799Z" fill="#BC7228"/>
                <path d="M270.1 195.9L288.6 179.6C288.5 179.6 271.8 186 270.1 195.9Z" fill="#BC7228"/>
                <path d="M258.3 180.9L262.4 195.1C262.5 195 255.1 188 258.3 180.9Z" fill="#BC7228"/>
                <path d="M265.1 207L273.4 227.8C273.4 227.8 274.5 211.8 265.1 207Z" fill="#BC7228"/>
                <path d="M240.6 223.9L258.3 207.9C258.3 207.9 242.8 212.7 240.6 223.9Z" fill="#BC7228"/>
                <path d="M312 229.5H273.8H273.1H234.9C231.7 229.5 229 231.2 229 233.4C229 235.5 231.6 237.3 234.9 237.3H271.1V311.1C264.1 311.8 255.1 313.2 255.1 313.2V317.5H299.3V313.2C299.3 313.2 290.7 311.8 283.9 311.1V237.3H312C315.2 237.3 317.9 235.6 317.9 233.4C317.8 231.3 315.2 229.5 312 229.5Z" fill="#A38260"/>
                <path d="M272.4 237.4H282.9C282.9 237.4 277.3 242.2 272.4 237.4Z" fill="#8C6D51"/>
                <path d="M248 208.6V214C248 217.7 250.7 220.7 254.2 221.2V228.7H251.5V229.5H258.5V228.7H255.8V221.2C259.5 220.9 262.4 217.8 262.4 214V208.6H248Z" fill="#787C91"/>
                <path d="M284.3 199.099H281.6V188.299H276.3V199.099H274.3C271.2 199.099 268.6 201.599 268.6 204.799V229.599H290V204.799C290 201.599 287.5 199.099 284.3 199.099Z" fill="#91B0DC"/>
                <path d="M290 206.799H268.6V221.199H290V206.799Z" fill="#787C91"/>
                <path d="M282.7 185.6H274.9V189.6H282.7V185.6Z" fill="#A38260"/>
                <path d="M240.2 130.301C240.2 130.301 231.2 122.701 234.5 111.601C237.7 100.501 252.7 92.0007 270.6 95.5007C288.5 99.0007 302.8 118.001 304.2 130.301H240.2Z" fill="#E8AD3A"/>
                <path d="M300.2 105.501C300.2 105.501 304.5 101.201 311.4 101.201L308.7 112.501L300.2 105.501Z" fill="#9B6F17"/>
                <path d="M290.3 105.902L285.4 98.6016C285.4 98.6016 277.6 104.902 280.4 112.602C283.1 120.202 290.3 105.902 290.3 105.902Z" fill="#9B6F17"/>
                <path d="M282.1 108.2C282.1 108.2 272.4 115.3 273.4 123C274.4 130.7 285.1 132.1 300.1 131.3C315.2 130.5 313 120.4 312.4 117.5C311.9 114.6 301.2 93.5003 282.1 108.2Z" fill="#E8AD3A"/>
                <path d="M283.5 113.201C283.5 113.201 284.5 116.501 288.9 116.701C293.2 116.901 293.2 113.501 293.2 113.501C293.2 113.501 288.6 118.301 283.5 113.201Z" fill="#9B6F17"/>
                <path d="M289.8 119.001C289.8 119.001 292.1 117.101 296 117.701C300 118.301 301.1 120.601 301.4 121.601C301.7 122.601 300 126.301 295 125.501C290.1 124.801 287.2 121.101 289.8 119.001Z" fill="#FFD068"/>
                <path d="M297.8 113.801C297.8 113.801 298.8 117.101 303.2 117.301C307.5 117.501 307.5 114.101 307.5 114.101C307.5 114.101 302.9 118.901 297.8 113.801Z" fill="#9B6F17"/>
                <path d="M294.1 118.501C294.1 118.501 296.3 118.301 297.2 119.001C298.1 119.601 295.4 121.501 295.4 121.501C295.4 121.501 292.5 119.201 294.1 118.501Z" fill="#9B6F17"/>
                <path d="M278.5 111.5C278.5 111.5 265.6 123.2 281.2 130.3C281.2 130.3 270.9 124.2 278.5 111.5Z" fill="#CC9533"/>
                <path d="M257.4 127.601C267.5 126.201 270.3 124.701 271.6 128.701C272.9 132.701 248.4 138.401 240.2 130.301C232 122.301 234 113.701 234 113.701C234 113.701 238.4 130.301 257.4 127.601Z" fill="#9B6F17"/>
                <path d="M270.9 200.4C270.9 200.4 268.3 229.5 276.1 239.2L270.9 200.4Z" fill="#47436D"/>
                <path d="M175.6 235.899C175.6 235.899 170.9 241.799 164.1 249.599C163.5 250.299 162.9 250.899 162.3 251.599C160 254.199 156.3 254.199 152.3 252.799C147.2 250.999 141.6 246.999 137.4 243.299C135 241.199 133.1 239.299 132 237.899C129.9 235.699 129.3 231.899 129.3 231.899C128.3 231.099 127.8 227.699 131.6 229.699C133.2 230.499 137.3 231.699 141.6 232.899C147.8 234.499 154.3 235.999 154.3 235.999C155 236.199 155.7 236.399 156.4 236.799L162.2 227.299L175.6 235.899Z" fill="#C45B51"/>
                <path d="M137.4 243.299C135 241.199 133.1 239.299 132 237.899C129.9 235.699 129.3 231.899 129.3 231.899C128.3 231.099 127.8 227.699 131.6 229.699C133.2 230.499 137.3 231.699 141.6 232.899C141.7 233.299 143.5 240.399 137.4 243.299Z" fill="#A0433F"/>
                <path d="M159.1 232.399C159.1 232.399 158.7 233.899 160.9 234.599C163.1 235.299 164.1 236.099 164.5 236.899C164.9 237.699 166.3 238.899 167.7 239.099C169.1 239.299 170.8 240.599 170.7 241.799L171.8 240.499C171.8 240.499 171.7 238.699 169.4 237.899C167.2 237.099 165.4 236.199 165 235.399C164.6 234.599 162.8 233.399 161.4 233.299C160.1 233.299 159.8 231.199 159.8 231.199L159.1 232.399Z" fill="#A0433F"/>
                <path d="M159.7 246.399C159.7 246.399 161.2 246.299 162 244.699C162.8 242.999 162.6 242.299 162.6 242.299C162.6 242.299 165.2 246.999 159.7 246.399Z" fill="#B25454"/>
                <path d="M164.1 249.599C163.5 250.299 162.9 250.899 162.3 251.599C160 254.199 156.3 254.199 152.3 252.799C152.3 252.799 154.6 248.699 158 248.499C161.4 248.299 164.1 249.599 164.1 249.599Z" fill="#A0433F"/>
                <path d="M131.9 232.3L136.8 233.1C136.8 233.1 134.7 231 131.9 232.3Z" fill="#843131"/>
                <path d="M406.3 214.799C406.3 214.799 384.4 252.299 357.6 247.399C330.8 242.499 326.1 168.199 326.1 168.199H338.3C338.3 168.199 349.5 211.299 359.1 214.799C368.8 218.199 381.2 204.699 381.2 204.699C381.2 204.699 400.1 197.899 406.3 214.799Z" fill="#E2B288"/>
                <path d="M338.3 168.499C338.3 168.499 339.8 165.299 340.8 160.199C341.8 154.999 337.2 150.399 336.6 149.099C336 147.799 333.7 148.399 335.4 154.099C337.1 159.799 332.9 155.899 330.1 151.899C327.3 147.899 329.8 143.299 326.1 138.999C322.5 134.599 322.9 142.599 323.5 145.299C323.5 145.299 321.5 146.399 321.1 148.899C320.7 151.499 321.1 153.099 321.1 153.099C321.1 153.099 319.8 154.499 319.9 156.699C320.1 158.899 320.6 159.299 320.6 159.299C320.6 159.299 319.8 160.599 320.4 163.399C321 166.199 326.8 174.499 326.8 174.499L338.3 168.499Z" fill="#E2B288"/>
                <path d="M406.3 173.199L397.7 182.999C397.7 182.999 413.1 192.399 421.8 189.999L437 172.299C437 172.299 412.7 178.199 406.3 173.199Z" fill="#E2B288"/>
                <path d="M441.4 162.9C439.4 160.1 437.4 159.9 436.2 160.1C438.8 150.8 437.9 138.4 419.5 131.5C419.5 131.5 412.9 134.2 410.8 141C408.7 147.8 400 147.9 400 147.9C400 147.9 397.3 160.2 393.7 166.9C393.7 166.9 399.5 175.2 413.2 176.8C426.9 178.4 430.4 172.2 430.4 172.2C438.5 175.9 444.5 167.2 441.4 162.9Z" fill="#F0CBA5"/>
                <path d="M417 126.3C417 126.3 416.6 134.4 425.6 140.6C434.6 146.8 434.7 146.4 434.7 146.4C434.7 146.4 427.7 152.6 429.3 158.2C429.3 158.2 426.2 162.4 428.3 164.4C428.3 164.4 433.2 160 437 160C440.8 160 444.4 167.5 436.9 172.2C436.9 172.2 432 173.1 430.3 171.9C430.3 171.9 427.8 173.3 427.5 175.3C427.5 175.7 428.9 180.1 430 180.1C430 180.1 440.1 176.8 445.6 172.7C451.1 168.6 459.7 158.9 455.3 150.6C455.3 150.6 454.4 136.8 437.3 128.1C420.3 119.5 417.3 122.5 417 126.3Z" fill="#3E3E44"/>
                <path d="M302.9 195.699L118.1 171.699L112.4 188.499C112.4 188.499 239 233.499 272.9 234.899C306.8 236.299 302.9 195.699 302.9 195.699Z" fill="#47436D"/>
                <path d="M397.7 183C397.7 183 415.8 190 423.3 188.2C423.3 188.2 423.2 212.1 407.1 229.5C391 246.9 371.6 248.1 338.5 247.7C306.5 247.4 305 245.6 305 245.6L300.7 195.6L355.5 191C355.5 191 383.1 183.4 397.7 183Z" fill="#D77062"/>
                <path d="M340.2 162.199C340.2 162.199 349 193.499 377.9 193.899C406.8 194.299 430.4 172.199 430.4 172.199" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M301.6 195.6C301.6 195.6 270.3 166.4 240 157.7C209.7 149 159.1 226.6 159.1 226.6L177.7 237.3L233.9 194.5C233.9 194.5 252.7 240.8 305 245.7L301.6 195.6Z" fill="#504977"/>
                <path d="M410.8 211.399C410.8 211.399 388.9 248.899 362.1 243.999C335.3 239.099 330.6 164.799 330.6 164.799H342.8C342.8 164.799 354 207.899 363.6 211.399C373.3 214.799 385.7 201.299 385.7 201.299C385.7 201.299 404.5 194.499 410.8 211.399Z" fill="#F0CBA5"/>
                <path d="M371.5 206L395.7 232.5C395.7 232.5 416.1 214.9 414 206.8C411.9 198.7 400.2 183.4 371.5 206Z" fill="#C45B51"/>
                <path d="M339 157C338 157.4 337 156.9 336.6 156L323.3 122.2C322.9 121.2 323.4 120.2 324.3 119.8C325.3 119.4 326.3 119.9 326.7 120.8L340 154.6C340.5 155.6 340 156.6 339 157Z" fill="#666666"/>
                <path d="M342.7 164.999C342.7 164.999 344.2 161.799 345.2 156.699C346.2 151.499 341.6 146.899 341 145.599C340.4 144.299 338.1 144.899 339.8 150.599C341.5 156.299 337.3 152.399 334.5 148.399C331.7 144.399 334.2 139.799 330.5 135.499C326.9 131.099 327.3 139.099 327.9 141.799C327.9 141.799 325.9 142.899 325.5 145.399C325.1 147.999 325.5 149.599 325.5 149.599C325.5 149.599 324.2 150.999 324.3 153.199C324.5 155.399 325 155.799 325 155.799C325 155.799 324.2 157.099 324.8 159.899C325.4 162.699 331.2 170.999 331.2 170.999L342.7 164.999Z" fill="#F0CBA5"/>
                <path d="M433.7 165.399C433.7 165.399 431.8 167.099 432.5 167.999C433.2 168.899 430.1 171.399 430.1 171.399C430.1 171.399 430.6 172.199 431.3 172.499C431.3 172.499 435.3 169.699 435.7 167.299C435.8 166.899 435.8 166.499 435.7 166.199C435.6 165.199 434.4 164.799 433.7 165.399Z" fill="white"/>
                <path d="M413.4 142.4C413.4 142.4 415.3 141.7 417.9 143.7C420.5 145.7 420.1 148.3 420.1 148.3C420.1 148.3 417.1 143.3 413.4 142.4Z" fill="#666666"/>
                <path d="M413.5 148.199C412.5 149.499 411.4 150.399 410.9 149.999C410.5 149.699 410.9 148.299 411.9 146.999C412.9 145.699 414 144.799 414.5 145.199C415 145.599 414.5 146.799 413.5 148.199Z" fill="#666666"/>
                <path d="M399 157.799C399 157.799 403.9 161.199 407.4 160.799C407.4 160.799 400.5 164.399 399 157.799Z" fill="#C6826D"/>
                <path d="M234.9 180.5V191.9C234.9 191.9 241.1 186 234.9 180.5Z" fill="#47436D"/>
                <path d="M364.5 213L366 223.9C366 223.9 370.2 218.4 364.5 213Z" fill="#E2B288"/>
                <path d="M117.5 173.6L115 189.3C115 189.3 107.7 187.4 97.8001 184.2C97.0001 183.9 96.1001 183.6 95.2001 183.4C91.8001 182.3 90.4001 179 90.1001 174.8C89.8001 170.5 90.6001 165.2 91.9001 160.6C92.2001 159.5 92.5001 158.4 92.8001 157.3C93.8001 154.3 94.8001 151.7 95.6001 150.1C95.6001 149.9 95.6001 149.8 95.6001 149.6C95.9001 145.4 99.5001 144 100 145.3C100.3 146.1 100.4 147.4 100.5 148.4C101 148.6 101.4 148.9 101.7 149.3C102.2 150.1 103.6 152.8 103.8 153.3C103.9 153.6 103.9 154.9 103.8 155.9C103.9 156.2 104 156.6 104 156.9C104.2 158.1 104.4 159.3 104.6 160.5C104.6 160.6 104.6 160.7 104.6 160.8C105.5 165.7 106.3 169.8 106.3 169.8C106.4 170.5 106.5 171.3 106.5 172L117.5 173.6Z" fill="#C45B51"/>
                <path d="M111.6 172.801C111.6 172.801 110 173.001 110.3 175.301C110.5 177.601 110.2 178.801 109.6 179.501C109 180.201 108.5 182.001 108.9 183.401C109.3 184.801 108.8 186.901 107.6 187.201L109.3 187.701C109.3 187.701 110.9 186.901 110.8 184.501C110.6 182.101 110.8 180.201 111.3 179.501C111.9 178.801 112.3 176.601 111.8 175.401C111.3 174.201 113.1 173.101 113.1 173.101L111.6 172.801Z" fill="#A0433F"/>
                <path d="M99 178.9C99 178.9 99.7 180.3 101.5 180.3C103.4 180.4 103.9 179.9 103.9 179.9C103.9 179.9 100.7 184.2 99 178.9Z" fill="#B25454"/>
                <path d="M97.9002 184.201C97.0002 183.901 96.2002 183.701 95.3002 183.401C91.9002 182.301 90.5002 179.001 90.2002 174.801C90.2002 174.801 94.9002 175.201 96.4002 178.301C97.9002 181.301 97.9002 184.201 97.9002 184.201Z" fill="#A0433F"/>
                <path d="M104.7 160.8C98.2999 157 94.4999 159.4 91.8999 160.5C92.1999 159.4 92.4999 158.3 92.7999 157.2C93.7999 154.2 94.7999 151.6 95.5999 150C95.5999 149.8 95.5999 149.7 95.5999 149.5C95.8999 145.3 99.4999 143.9 99.9999 145.2C100.3 146 100.4 147.3 100.5 148.3C101 148.5 101.4 148.8 101.7 149.2C102.2 150 103.6 152.7 103.8 153.2C103.9 153.5 103.9 154.8 103.8 155.8C103.9 156.1 104 156.5 104 156.8C104.2 158 104.4 159.2 104.6 160.4C104.7 160.6 104.7 160.7 104.7 160.8Z" fill="#A0433F"/>
                <path d="M100.5 149.4V153.9C100.5 154 102 151.3 100.5 149.4Z" fill="#843131"/>
                <path d="M173.4 223.5L230.1 183C230.1 183 193.5 214.1 175.1 223.8C173.7 224.5 173.8 223.7 173.4 223.5Z" fill="#5D5984"/>
                <path d="M232.1 181.6C232.1 181.6 233.3 178.8 235.6 179.1C237.9 179.4 238.8 181 238.8 181L297.6 222.6C297.6 222.6 247 183.7 239.8 180.5C239.8 180.5 234.6 174.6 232.1 181.6Z" fill="#5D5984"/>
                <path d="M167.6 217.699L185.6 229.699C185.6 229.699 171.1 225.899 167.6 217.699Z" fill="#5D5984"/>
                <path d="M161.5 226.199L167.2 219.299C167.2 219.299 164.6 226.399 161.5 226.199Z" fill="#5D5984"/>
                <path d="M168.9 229.4L172.5 225.9C172.5 225.9 172.1 229.2 168.9 229.4Z" fill="#5D5984"/>
                <path d="M175.6 232.8L180.8 229.4C180.8 229.4 180.3 233.7 175.6 232.8Z" fill="#5D5984"/>
                <path d="M127.1 175.1L123.7 190.4C123.7 190.4 122.3 176.6 127.1 175.1Z" fill="#3A3859"/>
                <path d="M119.3 172.799L126.1 174.299C126.1 174.199 122 175.599 119.3 172.799Z" fill="#3A3859"/>
                <path d="M117.5 180.199L123 181.499C123 181.599 119.6 183.199 117.5 180.199Z" fill="#3A3859"/>
                <path d="M116.9 186.9L122.7 188.3C122.7 188.4 118.7 189.3 116.9 186.9Z" fill="#3A3859"/>
</svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
