import React from 'react'
import Helmet from 'react-helmet'

import LesSerresDellarossa from "../img/realisations/les-serres-dellarossa.png"
import BarilDeco from "../img/realisations/baril-deco.png"
import FutureOnPrint from "../img/realisations/future-onprint.png"
import HOC from "../img/realisations/hoc.png"
import LMDW from "../img/realisations/lmdw.png"
import LesCreas from "../img/realisations/les-crea-dhoara.png"

// import verticalBlank from "../img/vertical_blank.png";

const reals = [
    {
        logo: LesSerresDellarossa,
        brand: 'Les Serres Dellarossa',
        link: 'https://les-serres-dellarossa.fr'
    },
    {
        logo: BarilDeco,
        brand: 'Baril Deco',
        link: 'https://custom.barildeco.fr'
    },
    {
        logo: HOC,
        brand: 'House Of Custom',
        link: 'https://houseofcustom.fr'
    },
    {
        logo: FutureOnPrint,
        brand: "Future On'Print",
        link: 'https://future-onprint.com'
    },
    {
        logo: LMDW,
        brand: "Le Meilleur de Twitch",
        link: 'https://lmdw.fr/'
    },
    {
        logo: LesCreas,
        brand: "Les Création d'Hoara",
        link: 'https://lescreadhoara.com/'
    },


].sort(() => Math.random() - 0.5)

const Realisation = () => {
    return (
        <>
            <Helmet>
                <title>_blank | Nos Réalisation</title>
            </Helmet>

            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-left">
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                            Nos Réalisations
                        </p>
                        <p className="max-w-xl mt-5 text-xl text-gray-500">
                            On dit qu’une image vaut mieux que mille mots, on vous montre nos créations. Ils ont su nous faire confiance.
                        </p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                    <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                        {reals.map(real => (
                            <a href={real.link} target="_blank" rel="noreferrer">
                                <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                                    <img className="max-h-12" src={real.logo} alt={real.brand}/>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>

                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                        <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                                <div className="lg:self-center">
                                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                                        <span className="block">Prêt à démarrer ?</span>
                                        <span className="block">Commencez dés aujourd’hui.</span>
                                    </h2>
                                    <p className="mt-4 text-lg leading-6 text-white">
                                        Commencez à travailler avec nous dés aujourd’hui. Recevez une estimation de votre projet.
                                    </p>
                                    <a
                                        href="/contact"
                                        className="mt-8 bg-orange-500 border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white"
                                    >
                                        Nous Contacter
                                    </a>
                                </div>
                            </div>
                            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                                {/*<img*/}
                                {/*    className="transform h-20 translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"*/}
                                {/*    src={verticalBlank}*/}
                                {/*    alt="App screenshot"*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                    <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img
                                className="max-h-12"
                                src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
                                alt="Workcation"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img className="max-h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage" />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img className="max-h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple" />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img className="max-h-12" src="https://tailwindui.com/img/logos/laravel-logo-gray-400.svg" alt="Laravel" />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img
                                className="max-h-12"
                                src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
                                alt="StaticKit"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img
                                className="max-h-12"
                                src="https://tailwindui.com/img/logos/statamic-logo-gray-400.svg"
                                alt="Statamic"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img
                                className="max-h-12"
                                src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg"
                                alt="Workcation"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img className="max-h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage" />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img className="max-h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple" />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img className="max-h-12" src="https://tailwindui.com/img/logos/laravel-logo-gray-400.svg" alt="Laravel" />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img
                                className="max-h-12"
                                src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg"
                                alt="StaticKit"
                            />
                        </div>
                        <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
                            <img
                                className="max-h-12"
                                src="https://tailwindui.com/img/logos/statamic-logo-gray-400.svg"
                                alt="Statamic"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Realisation
