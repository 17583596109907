import React, { useEffect } from  'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Aos from 'aos';
import 'aos/dist/aos.css';

import Home from './pages/Home'
import Contact from './pages/Contact'
import Realisation from './pages/Realisation'
import Services from './pages/Services'
import About from './pages/About'
import JobOffer from './pages/JobOffer';
import legals from './pages/Legals'
import Cookies from './pages/Cookies'
import NotFound from './pages/NotFound'
import Article from "./pages/Article";

import Navbar from './components/Navbar.jsx'
import Footer from './components/Footer.jsx'

export default function App() {
  useEffect(() => {
    Aos.init({
      duration: 1100,
      easing: 'ease',
      once: true,
    })
  }, [])

  return (
    <>
      <Navbar />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/realisation" component={Realisation} />
            <Route exact path="/a-propos" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/nous-rejoindre" component={JobOffer} />
            <Route exact path="/condition-de-vente" component={legals} />
            <Route exact path="/politique-des-cookies" component={Cookies} />
            <Route exact path="/article" component={Article} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      <Footer />
    </>
  );
}
