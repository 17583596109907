import { useState } from 'react'

const LogicModale = () => {
    const [ open, changeRevele ] = useState(false)

    function toggle() {
        changeRevele(!open)
    }

    return {
        open,
        toggle
    }
}

export default LogicModale
