import ContactModal from "./contactModal.jsx";
import LogicModale from "./logicModale";
import { ChatAlt2Icon, ClipboardListIcon, SupportIcon } from '@heroicons/react/outline'

const supportLinks = [
  {
    delay: 100,
    name: 'Demande de devis',
    href: 'tel:+33757595957',
    description:
      'Que vous ayez un cahier des charges ou simplement une idée, contactez nous pour obtenir une estimation et un avis technique sur votre projet.',
    icon: ClipboardListIcon,
  },
  {
    delay: 200,
    name: 'Support technique',
    href: 'tel:+33757595957',
    description:
      'Un souci avec votre application votre hébergement ou tout autre service informatique ? Que ce service soit fourni par _blank ou un autre, nous pouvons vous aider !',
    icon: SupportIcon,
  },
  {
    delay: 300,
    name: 'Autre',
    href: 'mailto:contact@underscore-blank.io',
    description:
      'Besoin de plus de détails, une question commerciale ou d’informations légales ou technique à notre propos, nous pouvons vous répondre.',
    icon: ChatAlt2Icon,
  },
]

export default function Support() {
  const { open, toggle } = LogicModale()

  return (
    <div className="bg-white">
      <div className="relative pb-32 bg-gray-800">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src="https://images.unsplash.com/photo-1547954575-855750c57bd3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
            alt="page de code"
          />
          <div className="absolute inset-0 bg-gray-700 mix-blend-multiply" aria-hidden="true" />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">Contactez-nous</h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">
            Vous trouverez tout ce d’ont vous avez besoin pour nous contacter.
            Nous faisons les choses bien, promis on réponds.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-10 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          Contact us
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div key={link.name} data-aos="fade-up" data-aos-delay={link.delay} className="flex flex-col bg-white rounded-2xl shadow-xl">
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-orange-400 rounded-xl shadow-lg transform -translate-y-1/2">
                  <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                <p className="mt-4 text-base text-gray-500">{link.description}</p>
              </div>
              <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                <button onClick={toggle} type="button" className="text-base font-medium text-orange-400 hover:text-orange-500">
                  Nous contacter <span aria-hidden="true"> &rarr;</span>
                </button>
              </div>
            </div>
          ))}
        </div>
        <ContactModal open={open} close={toggle} />
      </section>
    </div>
  )
}
