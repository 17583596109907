import Browser from '../../img/GoogleChromeDark.png'
import RippleButton from "../btnRipple";

export default function HostingSection() {
    return (
      <div className="relative bg-gray-50 pt-16 overflow-hidden sm:pt-24 lg:pt-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-orange-400 uppercase">HÉBERGEMENT</h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Pas de serveur ? Pas de problème.
            </p>
            <p className="mt-5 max-w-prose mx-auto text-lg text-gray-500 mb-10">
                Ne vous compliquez pas la tâche, hébergez votre projet directement chez nous. 
                _blank vous offre la possibilité de mettre en ligne votre Wordpress, Prestashop, et bien plus encore à petit prix.
            </p>
            <RippleButton href="https://underscore-blank.io/plaquette-tarifaire.pdf" value="Voir nos tarifs" target="_blank" />
          </div>
          <div className="mt-12 -mb-10 sm:-mb-24 lg:-mb-80">
            <img className="shadow-xl -z-10" src={Browser} alt="Google Chrome browser window" />
          </div>
        </div>
      </div>
    )
}
  