import React from 'react'

const RippleButton = ({href, target, value, bgColor = 'bg-orange-500', color = 'text-white'}) => {
    const onClick = event => {
        const button = event.target

        const appendRipple = document.createElement('div')
        appendRipple.classList.add('ripple')

        const size = button.offsetWidth * 2 + 'px'
        appendRipple.style.height = appendRipple.style.width = size

        const x = event.clientX - button.offsetLeft - button.offsetWidth
        const y = event.clientY - button.offsetTop - button.offsetWidth

        appendRipple.style.left = `${x}px`
        appendRipple.style.top = `${y}px`

        button.appendChild(appendRipple)

        setTimeout(() => {
            button.removeChild(appendRipple)
        }, 600)
    }

    document.querySelectorAll('.btn-ripple').forEach(button => {
        button.addEventListener('click', onClick)
    })

    return (
        <a 
        href={href} target={target}
        className={`btn-ripple whitespace-nowrap inline-flex items-center justify-center px-4 py-2 rounded-md hover:btn-ripple-hover text-base font-medium ${bgColor} ${color}`}
        >
        {value}
        </a>
    )
}

export default RippleButton