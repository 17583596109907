export default function Header() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="text-left">
                    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                        Nos missions
                    </p>
                    <p className="max-w-xl mt-5 text-xl text-gray-500">
                        Vous cherchez à travailler dans le domaine de l’informatique ou tout simplement trouver une mission pour freelance à votre taille, nous avons ce qu’il vous faut.
                    </p>
                </div>
            </div>
        </div>
    )
}
