export default function Header() {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-left">
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Qui sommes nous ?
            </p>
            <p className="max-w-xl mt-5 text-xl text-gray-500">
              Des humains, principalement. Découvrez notre équipe prête à vous accompagner dans tous vos projets.
            </p>
          </div>
        </div>
      </div>
    )
}
