import { Fragment } from 'react'
import { PhoneIcon, MailIcon, XIcon } from '@heroicons/react/outline'

function sendEmail() {
    window.location.assign('mailto:contact@underscore-blank.io')
}
function phoneCall() {
    window.location.assign('tel:+33757595957')
}

const ContactModal = ({open, close}) => open ? (
        <Fragment>
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-75" />
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg px-6 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" onClick={close}>
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Comment souhaitez-vous nous contacter ?
                                </h3>
                            </div>
                            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                <button
                                    id="mailing"
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white max-w-2xl text-base font-medium text-gray-700 focus:outline-none sm:col-start-2 sm:text-sm"
                                    onClick={sendEmail}
                                >
                                    <div className="flex flex-col justify-center items-center">
                                        <MailIcon className="w-10 h-10 my-5" />
                                        <h3 className="text-base text-gray-700 font-bold">Par mail</h3>
                                        <span>contact@underscore-blank.io</span>
                                        <footer className="mt-5">Réponse sous 48h.</footer>
                                    </div>
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 max-w-2xl focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={phoneCall}
                                >
                                    <div className="flex flex-col justify-center items-center">
                                        <PhoneIcon className="w-10 h-10 text-gray-700 my-5" />
                                        <h3 className="text-base text-gray-700 font-bold">Par téléphone</h3>
                                        <span>+33 7 57 59 59 57</span>
                                        <footer className="mt-5">Ouvert du Lundi au Vendredi de 9h00 à 12h00 et de 14h00 à 17h00.</footer>
                                    </div>
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
) : null

export default ContactModal
