import React from 'react'

export default function Hero() {
    return (
        <div className="relative">
            <div className="max-w-7xl mx-auto sm:px-6">
                <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                    <div className="absolute inset-0">
                        <img className="h-full w-full object-cover"
                             src="https://images.unsplash.com/photo-1547954575-855750c57bd3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80" 
                             alt="author: @rezanamdari"
                        />
                    </div>
                    <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                        <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                            <span className="block text-white">Propulsez vos projets</span>
                            <span className="block text-white">au sommet !</span>
                        </h1>
                        <p className="mt-6 max-w-lg mx-auto text-center text-white text-xl text-indigo-200 sm:max-w-3xl">
                            _blank réalise votre site, application,
                            héberge votre projet, et bien plus encore !
                        </p>
                        <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                                <a href="#features"
                                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base text-black font-medium rounded-md shadow-sm bg-white hover:bg-indigo-50 sm:px-8"
                                >En savoir plus</a>
                                <a href="/contact"
                                    className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 sm:px-8"
                                >Commencer</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}