import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, AnnotationIcon, CurrencyEuroIcon, ViewGridIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, DocumentIcon, PencilIcon, DesktopComputerIcon } from '@heroicons/react/solid'
import RippleButton from './btnRipple.jsx'
import LongLogo from '../img/long-logo.svg'

const solutions = [
  {
    name: 'Développement web',
    description: 'Creations de sites web: portfolios, boutiques en lignes, applications de gestion, services d’intranets et bien plus encore.',
    href: '/services#development',
    icon: DesktopComputerIcon,
  },
  {
    name: 'Solutions informatiques',
    description: 'Le web ne nous conviens pas à tous, nous proposons également de nombreux services, intégrations et produits sur mesure pour tout vos besoins.',
    href: '/services#solutions',
    icon: PencilIcon,
  },
  {
    name: 'Hébergement',
    description: "Afin de simplifier votre aventure informatique, nous vous proposons des hébergements dédiés à vos solutions afin de vous éviter bien des tracas.",
    href: '/services#hosting',
    icon: DocumentIcon,
  },
  {
    name: 'Design',
    description: 'Des outils seuls ne suffisent pas, grâce à notre solutions d’infographie vous pouvez atteindre vos clients et vous faire reconnaitre en un clin d’oeil.',
    href: '/services#design',
    icon: ViewGridIcon,
  },
]
const callsToAction = [
  { name: 'Obtenir un devis', href: '/contact', icon: AnnotationIcon },
  { name: 'Tarifs', href: 'https://underscore-blank.io/plaquette-tarifaire.pdf', icon: CurrencyEuroIcon },
]
const resources = [
  {
    name: 'Réalisations',
    description: 'Explorez toutes nos réalisations.',
    href: '/realisation',
  },
  {
    name: 'Qui sommes nous ?',
    description: "Pour apprendre à mieux nous connaître, c'est ici.",
    href: '/a-propos',
  },
  {
    name: 'Services',
    description: 'Voici ce que nous proposons spécialement pour vous.',
    href: '/services',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  return (
    <Popover className="relative bg-white">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="/">
                  <span className="sr-only">_blank</span>
                  <img className="h-9" src={LongLogo} alt="logo d'_blank" />
                </a>
              </div>

              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                  <span className="sr-only">Ouvrir le menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>

              <Popover.Group as="nav" className="hidden md:flex space-x-10">

                <a href="/" className="text-base font-medium text-gray-500 hover:text-gray-900">Accueil</a>

                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none'
                        )}
                      >
                        <span>Services</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-50 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {solutions.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                >
                                  <item.icon className="flex-shrink-0 h-6 w-6 text-orange-500" aria-hidden="true" />
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                  </div>
                                </a>
                              ))}
                            </div>
                            <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                              {callsToAction.map((item) => (
                                <div key={item.name} className="flow-root">
                                  <a
                                    href={item.href}
                                    className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                    target="_blank" rel="noreferrer"
                                  >
                                    <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                    <span className="ml-3">{item.name}</span>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                <a href="/realisation" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  Réalisations
                </a>
                <a href="/a-propos" className="text-base font-medium text-gray-500 hover:text-gray-900">
                  Qui sommes nous ?
                </a>

              </Popover.Group>

              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <RippleButton href="/contact" value="Contactez-nous" />
              </div>
            </div>
          </div>

        {/* mobile menu */}

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="https://brands.underscore-blank.io/_blank/default/logo/raw/black/short.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                        <span className="sr-only">Fermer le menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      {solutions.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                        >
                          <item.icon className="flex-shrink-0 h-6 w-6 text-orange-500" aria-hidden="true" />
                          <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                    {resources.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="rounded-lg p-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                    {callsToAction.map((item) => (
                        <div key={item.name} className="flow-root">
                          <a
                              href={item.href}
                              className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                              target="_blank" rel="noreferrer"
                          >
                            <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                            <span className="ml-3">{item.name}</span>
                          </a>
                        </div>
                    ))}
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
