import React from 'react'
import Helmet from 'react-helmet'

const legals = () => {
    return (
        <>
        <Helmet>
            <title>_blank | Condition de Vente et Condition d'utilisation</title>
        </Helmet>

        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
              <svg
                className="absolute top-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
              </svg>
              <svg
                className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
              </svg>
              <svg
                className="absolute bottom-12 left-full transform translate-x-32"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="d3eb07ae-5182-43e6-857d-35c643af9034"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
              </svg>
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              <h1>
                <span className="block text-base text-center text-orange-400 font-semibold tracking-wide uppercase">
                  cgv & cgu
                </span>
                <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-indigo-600 sm:text-4xl">
                  Condition Générales de Ventes
                </span>
              </h1>
            </div>
            <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto text-justify">
              <p>
                Ce site web est exploité par _blank. Sur ce site, les termes "nous", "notre" et "nos" font référence à _blank. 
                _blank propose ce site web, y compris toutes les informations, tous les outils et tous les services qui y sont
                disponibles pour vous, l’utilisateur, sous réserve de votre acceptation de l’ensemble des modalités, conditions, 
                politiques et avis énoncés ici.
              </p>
              <p>
                En visitant ce site et/ou en achetant un de nos produits, vous vous engagez dans notre "Service" et acceptez d’être 
                lié par les modalités suivantes ("Conditions Générales de Vente", "Conditions Générales de Vente et d’Utilisation", 
                "Conditions"), y compris les modalités, conditions et politiques additionnelles auxquelles il est fait référence ici
                et/ou accessibles par hyperlien. Ces Conditions Générales de Vente et d’Utilisation s’appliquent à tous les utilisateurs
                de ce site, incluant mais ne se limitant pas, aux utilisateurs qui naviguent sur le site, qui sont des vendeurs, 
                des clients, des marchands, et/ou des contributeurs de contenu.
              </p>
              <p>
                Veuillez lire attentivement ces Conditions Générales de Vente et d’Utilisation avant d’accéder à ou d’utiliser notre site web.
                En accédant à ou en utilisant une quelconque partie de ce site, vous acceptez d’être lié par ces Conditions Générales de Vente et d’Utilisation.
                Si vous n’acceptez pas toutes les modalités et toutes les Conditions de cet accord, alors vous ne devez pas accéder au site web ni utiliser les services qui y sont proposés.
                Si ces Conditions Générales de Vente et d’Utilisation sont considérées comme une offre, l’acceptation se limite expressément à ces Conditions Générales de Vente et d’Utilisation.
              </p>
              <p>
                Toutes les nouvelles fonctionnalités et tous les nouveaux outils qui seront ajoutés ultérieurement à cette boutique seront également assujettis à ces Conditions Générales de Vente et d’Utilisation. 
                Vous pouvez consulter la version la plus récente des Conditions Générales de Vente et d’Utilisation à tout moment sur cette page. 
                Nous nous réservons le droit de mettre à jour, de changer ou de remplacer toute partie de ces Conditions Générales de Vente et d’Utilisation en publiant les mises à jour et/ou les modifications sur notre site web. 
                Il vous incombe de consulter cette page régulièrement pour vérifier si des modifications ont été apportées. Votre utilisation continue du site web ou votre accès à celui-ci après la publication de toute modification constitue une acceptation de votre part de ces modifications.
              </p>
              <p>
                Notre boutique est hébergée chez Planethoster. Ils nous fournissent la plate-forme e-commerce qui nous permet de vous vendre nos produits et services.
              </p>
              <p>
                Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae
                sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.
              </p>
              <h2>ARTICLE 1 – CONDITIONS D’UTILISATION DE NOTRE BOUTIQUE EN LIGNE</h2>
              <p>
                En acceptant ces Conditions Générales de Vente et d’Utilisation, vous déclarez que vous avez atteint l’âge de la majorité dans votre pays, 
                État ou province de résidence, et que vous nous avez donné votre consentement pour permettre à toute personne d’âge mineur à votre charge d’utiliser ce site web.
              </p>
              <p>
                L’utilisation de nos produits à toute fin illégale ou non autorisée est interdite, et vous ne devez pas non plus, dans le cadre de l’utilisation du Service, violer les lois de votre juridiction (incluant mais ne se limitant pas aux lois relatives aux droits d’auteur).
                Vous ne devez pas transmettre de vers, de virus ou tout autre code de nature destructive.
              </p>
              <p>
                Toute infraction ou violation des présentes Conditions Générales de Vente et d’Utilisation entraînera la résiliation immédiate de vos Services.
              </p>
              <h2>ARTICLE 2 – CONDITIONS GÉNÉRALES</h2>
              <p>
                Nous nous réservons le droit de refuser à tout moment l’accès aux services à toute personne, et ce, pour quelque raison que ce soit.
              </p>
              <p>
                Vous comprenez que votre contenu (à l’exclusion de vos informations de carte de crédit) pourrait être transféré de manière non chiffrée, et cela sous-entend (a) des transmissions sur divers réseaux; et (b) des changements pour se conformer et s’adapter aux exigences techniques pour la connexion des réseaux ou appareils. 
                Les informations de carte de crédit sont toujours chiffrées pendant la transmission sur les réseaux.
              </p>
              <p>
                Vous acceptez de ne pas reproduire, dupliquer, copier, vendre, revendre ou exploiter une quelconque partie du Service ou utilisation du Service, ou un quelconque accès au Service ou contact sur le site web, par le biais duquel le Service est fourni, sans autorisation écrite expresse préalable de notre part.
              </p>
              <p>
                Les titres utilisés dans cet accord sont inclus pour votre commodité, et ne vont ni limiter ni affecter ces Conditions.
              </p>
            </div>
          </div>
        </div>
        </>
      )
}

export default legals