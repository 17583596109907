const features = [
    { 
        number: '1',
        name: 'Prise de contact',
        description: 'Nous prendrons le temps d’écouter tout ce que vous avez à nous dire pour établir un cahier des charges.',
        delay: 100
    },
    { 
        number: '2',
        name: 'Solutions',
        description: 'En interne, nous prendrons le temps de réfléchir aux différentes solutions que nous pouvons vous proposer.',
        delay: 200
    },
    { 
        number: '3',
        name: 'Validation',
        description: 'Nous reviendrons vers vous pour présenter nos différentes solutions. À la signature du devis, nous attaquons le travail !',
        delay: 300
    },
    { 
        number: '4',
        name: 'Créativité',
        description: 'Ensemble, nous explorerons les possibilités créatives de votre projet. N’ayez pas peur de viser la lune, nous sommes là pour ça !',
        delay: 400
    },
    { 
        number: '5',
        name: 'Développement',
        description: 'Après validation de la maquette ou charte graphique, notre équipe se mobilise pour construire votre futur site préféré.',
        delay: 500
    },
    { 
        number: '6',
        name: 'Mise en service',
        description: 'C’est l’étape cruciale ! Après paiement de votre facture nous mettons tout en œuvre pour déployer votre projet.',
        delay: 600
    },
]
  
export default function Operation() {
    return (
        <div className="relative bg-white py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-orange-400 uppercase">Notre fonctionnement</h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    Travailler avec nous, comment ça fonctionne ?
                </p>
                <p className="mt-5 max-w-prose mx-auto text-lg text-gray-500">
                    Avec _blank pas d’entourloupe, nous vous expliquons de A à Z comment se déroule la prise en charge de votre projet.
                    Vous allez voir c’est simple !
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="pt-6">
                                <div data-aos="fade-up" data-aos-delay={feature.delay}>
                                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                                            <span className="inline-flex items-center justify-center p-3 bg-orange-500 rounded-md shadow-lg">
                                                <h1 className="h-6 w-6 text-white font-bold" aria-hidden="true">{feature.number}</h1>
                                            </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-bold text-gray-900 tracking-tight">{feature.name}</h3>
                                            <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}