import React from 'react'

import Hero from '../components/home/Hero.jsx'
import LogoClouds from '../components/home/LogoClouds.jsx'
import MainFeatures from '../components/home/MainFeatures.jsx'
import Features from '../components/home/Features.jsx'
import Operation from '../components/home/Operation.jsx'
import HostingSection from '../components/home/HostingSection.jsx'



const Home = () => {
    return (
        <>
            <Hero />
            <LogoClouds />
            <MainFeatures />
            <Features />
            <Operation />
            <HostingSection />
        </>
    )
}

export default Home
